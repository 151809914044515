import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import NavBar from '../Components/NavBar'

import Leader from '../assets/images/Leader.png'
import Background from '../assets/images/LeaderBg.png'
import { FONT } from '../utils/Font';

const OurLeadership = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <NavBar />
            <div className="AboutUsHome" style={{ marginTop:100,marginBottom:'100px' }}>
                <p style={{ color: COLORS.heading, fontSize: 30, fontWeight: 'bold', fontFamily: FONT.primaryFont, }}>Our Leadership</p>

                <Row>
                    <Col md={7}>
                        <ul style={{ textAlign: 'justify', marginBottom: 40, fontFamily: FONT.primaryFont, }}>
                            <li style={{paddingBottom:5}}>At Intellection, our vision and direction are shaped by our esteemed CEO and Founder. With a blend of innovation, strategic foresight, and unwavering dedication, Dr.Shailesh has been the cornerstone of our company's growth and success.</li>
                            <li style={{paddingBottom:5}}>Meet Our CEO and Founder,
Dr.Shailesh Deshpande, Chief Executive Officer (CEO) and Founder, an
IIT Bombay Alumni.</li>
                            <li style={{paddingBottom:5}}>Dr.Shailesh founded Intellection in 2005, driven by a passion for technology and a desire to solve real-world problems through innovative software solutions. With over 20+ years of experience in the industry, he has transformed the company from a nascent startup into a leading player in the software market.
</li>
<li>
                            Dr.Shailesh's vision for Intellection has always been clear: to create technology solutions that not only meet the needs of our clients but also exceed their expectations. Under his leadership, we have continuously pushed the boundaries of what is possible, embracing cutting-edge technologies and developing groundbreaking products that drive business success.
                            </li>
                        </ul>
                       
                    </Col>
                    <Col md={4} xs={12} >
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '-90px',
                                backgroundImage: `url(${Background})`,
                                backgroundSize: 'cover', // Make the background image cover the entire container
                                backgroundPosition: 'center',
                                width: '100%',
                                height: 400,
                                backgroundRepeat: 'no-repeat',
                                padding: 60,
                                display: 'flex', // Use flexbox to center the image horizontally and vertically
                                justifyContent: 'center',
                                alignItems: 'center',
                               
                               
                            }}
                        >
                            <img src={Leader} style={{marginTop:'-40px'}} width={240} height={240} />
                           
                        </div>
                    
                    </Col>

                </Row>
                {/* <p style={{fontWeight:'bold'}}>Visionary Leadership</p> */}
                        <ul style={{ textAlign: 'justify',  fontFamily: FONT.primaryFont,marginTop:'-30px' }}>
                           
                            <li style={{paddingBottom:5}}>As CEO, Dr.Shailesh is responsible for setting the strategic direction of the company. His deep understanding of market trends, combined with an ability to anticipate future needs, allows us to stay ahead of the competition. He is adept at identifying new opportunities and navigating the complexities of the ever-evolving tech landscape, ensuring that Intellection remains at the forefront of innovation.</li>
                            <li style={{paddingBottom:5}}>Dr.Shailesh's commitment to excellence permeates every aspect of our business. He believes in fostering a culture of continuous improvement, where quality and performance are paramount. This dedication to excellence is reflected in the high standards we set for our products and services, as well as in the exceptional results we deliver to our clients.</li>
                            {/* <li>Understanding the unique demands of each client is a hallmark of Shailesh's leadership. He emphasizes a personalized approach, ensuring that we listen to our clients, understand their specific needs, and develop tailored solutions that drive their success. He is deeply involved in client interactions, ensuring that our solutions are thoughtfully crafted with both technical precision and strategic insight.</li>
                            <li>Dr.Shailesh is not only a visionary leader but also an innovator who encourages creativity and forward-thinking within the team. He mentors our talented professionals, fostering an environment where ideas can flourish and innovation can thrive. His leadership style is collaborative and inclusive, empowering employees to take ownership of their work and contribute to our collective success.</li>
                            <li>Beyond leading Intellection, Dr.Shailesh is also an active participant in the broader tech community. He regularly engages with industry leaders, participates in conferences, and contributes thought leadership on emerging trends and technologies. Shailesh is committed to giving back to the community and often participates in initiatives that promote STEM education and support tech startups. His guidance and  investment in the youths of the company has helped them excel in the field of technology, with some of them serving in the tech- giant companies in the world.</li> */}
                        
                        </ul>
            </div>
        </>
    )
}
export default OurLeadership