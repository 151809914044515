import React from 'react';
import { Col, Row } from 'react-bootstrap';
import android from '../assets/images/androidnew.png'
import { FONT } from '../utils/Font';
import './OurWorkFlow.css';
const OurVision = () => {
    return (
        <>
            <div className='Experience' style={{ marginTop: 25 }}>
                <Row>
                    {/* <Col md={6}>
                        <img src={android} className="img-fluid" />
                    </Col> */}
                    <Col>
                        {/* <p style={{ fontSize: 38, fontWeight: 600, fontFamily: FONT.primaryFont }}>Innovative Solutions for Tomorrow</p> */}
                        <p style={{ fontSize: 16, fontWeight: 400, fontFamily: FONT.primaryFont, textAlign: 'justify' }}> At Intellection, we are committed to delivering excellence in software solutions, a commitment that has been unwavering since our inception by esteemed alumni of the Indian Institute of Technology Bombay (IIT Bombay). For over 15 years, we have partnered with organizations worldwide, helping them bring their visions to life with our expertise in crafting bespoke, comprehensive solutions. Our approach blends technical process with strategic business insight, allowing us to deliver exceptional results.</p>
                        <p style={{ fontSize: 16, fontWeight: 400, fontFamily: FONT.primaryFont, textAlign: 'justify' }}>We thrive on challenges and are driven by creativity and determination. The only way to do great work is to love what you do, and this belief fuels our passion for solving complex problems across various industries like <span style={{ fontWeight: 'bold' }}> Finance, Healthcare, Media & Entertainment.</span> It has also helped us build strong, enduring partnerships with our clients. We believe that staying ahead in the ever-evolving tech landscape requires continuous learning and a commitment to professional growth. That is why our team is always acquiring new knowledge and refining our skills to deliver solutions that are both cutting-edge and of genuine business value.</p>
                        <p style={{ fontSize: 16, fontWeight: 400, fontFamily: FONT.primaryFont, textAlign: 'justify' }}>Quality is more than a goal at Intellection; it is a promise. Our team carefully designs each solution, ensuring top-notch performance, security, and reliability every time. By staying abreast of the latest technological advancements and industry trends, we ensure our products are not only innovative but also compliant with the specific needs of each sector we serve. We emphasize on personalized approach, ensuring that we listen to our clients, understand their specific needs, and develop tailored solutions that drive their success. Working closely with our clients, we provide personalized support every step of the way, ensuring that our solutions drive real business value.</p>
                        {/* <p style={{ fontSize: 16, fontWeight: 400, fontFamily: FONT.primaryFont }}>At Intellection, we don't just develop software; we build partnerships. Working closely with our clients, we provide personalized support every step of the way, ensuring that our solutions drive real business value.</p> */}
                    </Col>
                </Row>
                <div className="quote-container">
                    <h5>
                        "The only way to do great work is to love what you do."
    <br />
                        <span>— Steve Jobs</span>
                    </h5>
                </div>




            </div>

            <div className='Experience' style={{ marginTop: 100 }}>
                <Row style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Col md={6} style={{ display: 'flex' }}>
                        <div style={{ border: '1px solid rgba(207, 207, 207, 1)', padding: 20, marginBottom: 10, flex: 1 }}>
                            <p style={{ color: 'rgba(61, 39, 77, 1)', fontSize: 42, fontFamily: FONT.primaryFont }}>Our Vision</p>
                            <p style={{ fontSize: '16px' }}>At Intellection Software, we aspire to be the trusted partner delivering innovative, robust, and scalable software solutions that drive our clients' growth while upholding the highest standards of integrity, transparency, excellence, and quality.</p>
                        </div>
                    </Col>
                    <Col md={6} style={{ display: 'flex' }}>
                        <div style={{ border: '1px solid rgba(207, 207, 207, 1)', padding: 20, marginBottom: 10, flex: 1 }}>
                            <p style={{ color: 'rgba(61, 39, 77, 1)', fontSize: 42, fontFamily: FONT.primaryFont }}>Our Mission</p>
                            <p style={{ fontSize: '16px' }}>We are committed to leading the charge in delivering cutting-edge software solutions that exceed client expectations, pushing technological boundaries, and setting new standards of excellence and innovation in the digital realm.</p>
                        </div>
                    </Col>
                </Row>
            </div>



        </>
    )
}
export default OurVision