import React from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import image1 from '../assets/images/slide1.png';
import image2 from '../assets/images/slide2.png';
import image3 from '../assets/images/slide3.png';
import image4 from '../assets/images/AINew.png';

import { FONT } from '../utils/Font';

import './NavBar.css';

const ImgCard = () => {
    return (
        <Carousel style={{ overflowX: 'hidden' }} interval={2500}>
            <Carousel.Item>
                <img src={image3} className='img-card' alt="First slide" />
                <Carousel.Caption className="left-caption1">
                    {/* <h1 className="caption-title1">Innovative software solutions to propel your business forward.</h1> */}
                    <h1 className="caption-title1">Your Idea + Our Expertise <span className="caption-title2"> = A Great Product.</span></h1>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image2} className='img-card' alt="Third slide" />
                <Carousel.Caption className="left-caption">
                    {/* <h1 className="caption-title">Design, develop, deliver: cutting-edge software solution that drives results.</h1> */}
                    <h1 className="caption-title" style={{fontFamily:FONT.primaryFont}}>Innovative Software Solutions to Propel Your Business Forward.</h1>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image1} className='img-card' alt="Second slide" />
                <Carousel.Caption className="left-caption">
                    <h1 className="caption-title" style={{fontFamily:FONT.primaryFont,width:'90%'}}>Strong client connections.</h1>
                    {/* <h3 className="caption-subtitle">We transform your concepts into market-ready products,</h3>
                    <p className="caption-text">prioritizing quality & putting your needs at the forefront.</p> */}
                </Carousel.Caption>
            </Carousel.Item>


            {/* <Carousel.Item>
                <Carousel.Caption className="left-caption">
                    <h1 className="caption-title">Design, develop, deliver: cutting-edge software solution
that drives results.</h1>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
    );
};

export default ImgCard;
