// CardDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import solution from '../assets/images/solution.png';
import mvp from '../assets/images/mvp.jpg';
import SoftwareSolutionsPartner from '../assets/images/softwaresolution.jpg';

import TechnologyPartner from '../assets/images/technologypartner.jpg';
import EndtoEndSoftwareProducts from '../assets/images/EndToEnd.jpg';
import { useNavigate } from 'react-router-dom';

import NavBar from './NavBar';
import { FaArrowLeftLong } from 'react-icons/fa6';

const cardData = [
    {
        id: 1,
        category: 'buildingmvp',
        img: mvp,
        h1: "Building an MVP",
        title: "Each of us faces a series of challenges when solving problems within our area of expertise. During this process, we often come up with ideas to address these issues. To determine whether these ideas add value to their respective fields, it's crucial to have a platform for testing them. However, due to busy schedules or a lack of the necessary skills to turn these ideas into tangible products, many individuals and organizations need support to bring their concepts to life.",
        text: "This is where Intellection steps in. We play a key role in analyzing ideas from a feasibility standpoint and transforming the most promising ones into Minimum Viable Products (MVPs) using cutting-edge technologies.",
        text1:" At Intellection, our approach is rooted in collaboration and precision. We work closely with our clients, leveraging our technical expertise and industry insights to shape and refine their concepts. Our goal is to ensure that the resulting MVP is not only functional but also aligned with the client's objectives.",
        para: "Over the years, Intellection has developed proprietary technologies from scratch and provided innovative solutions to our clients. We have also collaborated with clients on groundbreaking concepts, creating exclusive products that are not yet available in the market. By helping them develop these ideas into patented innovations, we ensure that all intellectual property rights are fully reserved for the client. From idea validation to product launch, we support our clients not only from a technical perspective but also from a business standpoint, ensuring that the developed MVP becomes a highly successful venture."
    },
    {
        id: 2,
        category: 'software-solution-partner',
        img: SoftwareSolutionsPartner,
        h1: "Software Solutions Partner",
        title: "Are you looking for a partner that can deliver comprehensive, end-to-end solutions tailored to your unique business challenges? At Intellection, we specialize in developing software solutions that address the specific needs and pain points of our clients. We take pride in immersing ourselves in our customer's environments, gaining a deep understanding of their operations, objectives, and challenges. By adopting our clients' perspectives, we are able to design and implement software solutions that not only resolve immediate issues but also provide sustainable, long term benefits. Our team of seasoned experts combines innovative technology with strategic thinking to empower your business. We believe that the right software solutions can transform how you operate, drive efficiency, and foster growth. Whether it is enhancing existing systems or developing new applications from scratch, Intellection is committed to delivering solutions that align with your business goals. With our comprehensive approach, we ensure that every solution is meticulously crafted to meet your specific requirements.",
        text: "Choosing Intellection as your software solutions partner means collaborating with a team dedicated to your success, with a focus on innovation, quality, and excellence. Our extensive experience across various industries enables us to provide solutions that are not only functional but also future-proof, capable of evolving with your business. We don't just deliver software; we build strategic partnerships that last. We offer ongoing support and continuous improvement, helping you adapt to new challenges and seize emerging opportunities. Together, we can navigate the complexities of the digital landscape and create software that makes a real difference to your business. Partner with Intellection and let’s drive your business forward with technology that truly works for you.",
        para: 'At Intellection, we understand that technology is constantly evolving, and so are the needs of your business. That is why we place a strong emphasis on staying updated with the latest technological advancements and industry trends. Our team continuously explores new methodologies, tools, and frameworks to ensure that we provide the most innovative and effective solutions possible. We believe in proactive problem-solving, always looking ahead to anticipate potential challenges and address them before they impact your business. With Intellection by your side, you gain not just a software provider but a strategic ally committed to your long-term success. Together, we can build a future where technology empowers your business to reach new heights.'
    },
    {
        id: 3,
        img: TechnologyPartner,
        category: "technology-partner",
        h1: "Technology Partner",
        title: "Do you need a technology partner that can provide advanced components to enhance your existing products or solutions? At Intellection, we offer a wide range of technological expertise designed to elevate your business to the next level. Our team is skilled in various cutting-edge fields, including data science, analytics, data visualization, machine learning, artificial intelligence, and cloud computing. We specialize in developing sophisticated technology components that are built on a strong mathematical foundation, ensuring they are accurate, efficient, and scalable. Whether you are looking to integrate advanced analytics into your software, develop AI-driven features, or leverage cloud computing for better scalability, Intellection has the expertise to make it happen. Our approach is not just about providing technology but also about understanding your business needs and delivering solutions that add real value.",
        text: "At Intellection, we work closely with you to understand your goals and develop technology that enhances your products and services. We believe that the key to successful technology integration lies in collaboration and transparency. By partnering with us, you gain access to a team of experts who are passionate about technology and dedicated to helping you succeed. We continuously stay at the forefront of technological advancements, ensuring that our clients benefit from the latest innovations and best practices. Our commitment is to transform your technological aspirations into reality and create innovative solutions that set you apart from the competition. Together, we can push the boundaries of what’s possible and help your business thrive in the digital age.",
        para: "Our goal at Intellection is to be more than just a technology provider; we aim to be your strategic partner in innovation. We understand that integrating new technologies can be a daunting task, which is why we offer comprehensive support throughout the entire process. From initial consultation to post-deployment maintenance, we are with you every step of the way, ensuring a seamless integration that maximizes value and minimizes disruption. Our team is dedicated to ongoing learning and adaptation, constantly refining our skills to keep pace with the rapid evolution of technology. By choosing Intellection, you are choosing a partner who is as invested in your success as you are. Let us help you harness the power of technology to drive your business forward and achieve your goals."
    },
    {
        id: 4,
        img: EndtoEndSoftwareProducts,
        category: 'end-to-end-sotfware-product',
        h1: "End to End Software Product",
        title: "Are you in search of innovative software solutions that are specifically tailored to meet your unique business requirements? At Intellection, we understand that every business is different, with its own set of challenges and opportunities. This recognition drives us to deliver customized software solutions that are not just functional but exceptional, exceeding the ordinary to achieve extraordinary results. Our approach begins with a thorough understanding of your business objectives, processes, and pain points. We work closely with you to define the scope and requirements of your project, ensuring that the final product aligns perfectly with your goals. From initial concept and design to development, testing, and deployment, we are with you every step of the way. Our team of experts is dedicated to crafting high quality software that meets the highest standards of performance, security, and reliability.",
        text: "We believe that the right software can transform your business, driving efficiency, innovation, and growth. At Intellection, we don’t just provide software services; we forge lasting partnerships. We are committed to your success, and we work tirelessly to ensure that your software not only meets but exceeds your expectations. We provide ongoing support and enhancement to ensure that the software evolves with your business needs. By choosing Intellection, you are selecting a partner who understands the importance of delivering solutions that make a real impact on your business. Let's embark on this remarkable journey together and build something truly great that stands the test of time.",
        para: "At Intellection, we pride ourselves on our ability to innovate and adapt to the unique needs of each client. We believe that building a successful software product is not just about writing code; it's about creating a solution that is perfectly aligned with your business strategy. Our agile development process allows us to be flexible and responsive, making adjustments as needed to ensure the final product is exactly what you envision. We focus on collaboration, keeping you involved and informed at every stage of development. This collaborative spirit ensures that the end product is not only technically sound but also fully aligned with your vision and goals. With Intellection as your partner, you can be confident that you are building a software product that will drive your business forward, today and into the future."
    }
];

const WhyChooseUsDetailPage = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    const { category } = useParams();
    const card = cardData.find(item => item.category === category);

    if (!card) return <p>Card not found!</p>;

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };
    return (
        <>
            <NavBar />
          
            <div className='WhatWeDoComponent' style={{ marginTop: '60px'}} >
            <button style={{ border: 'none', backgroundColor: 'white' }} onClick={handleBackClick}>
                    <FaArrowLeftLong style={{ marginRight: '8px', color: '#6F2DA8', fontSize: '30px',marginTop:'30px' }} />
                </button>
                <Row>

                    <Col md={6} style={{ marginTop: '20px' }}>
                        <h1>{card.h1}</h1>
                        <p style={{textAlign:'justify'}}>{card.title}</p>
                        {category === 'buildingmvp' && (
                            <p style={{ textAlign: 'justify' }}>{card.text}</p>
                        )}
                    </Col>

                    <Col md={6} style={{ marginTop: '70px' }}>
                        <img
                            src={card.img}
                            width={600}
                            height={350}
                            style={{
                                border: '1px solid #ddd',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
                            }}
                            className="img-fluid"
                        />
                    </Col>

                </Row>
                {category !== 'buildingmvp' && (
                    <>
                        <p style={{ textAlign: 'justify',marginTop:'50px' }}>{card.text}</p>
                    </>
                )}
                <p style={{ textAlign: 'justify' }}>{card.text1}</p>
                <p style={{ textAlign: 'justify' }}>{card.para}</p>
            </div>
        </>
    );
};

export default WhyChooseUsDetailPage;
