import React from 'react'
import Contact from '../Components/Contact'
import NavBar from '../Components/NavBar'
import Navv from '../Components/Navv'

const ContactUs =()=>{
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
        <NavBar />
        <Contact />
        </>
    )
}
export default ContactUs