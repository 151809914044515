import React from 'react'
import ContactWithUs from '../Components/ContactWithUs'
import NavBar from '../Components/NavBar'
import OurProduct from "../Components/OurProduct"

const OurProducts = () => {
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <OurProduct />
            <ContactWithUs />
        </>
    )
}
export default OurProducts