import React from 'react'
import OurServices from '../Components/OurServices'
import NavBar from '../Components/NavBar'
import OurExpertise from '../Components/OurExpertise'
import ContactWithUs from '../Components/ContactWithUs'
import CustomSoftware from '../Components/CustomSoftware'
import TechStack from '../Components/TechStack'
import StandardsAndControls from '../Components/StandardsAndControls'

const Services = () => {
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <CustomSoftware />
           <OurServices />
           <OurExpertise  />
           <TechStack />
           <ContactWithUs />
           <StandardsAndControls />

        </>
    )
}
export default Services