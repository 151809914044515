import React, { useState } from 'react';
import email from '../assets/images/email.png';
import call from '../assets/images/phone-call.png';
import linkedin from '../assets/images/linkedin.png';
import Logo from '../assets/images/intelection.png';
import { COLORS } from '../utils/Colors';
import Humburger from '../assets/images/hamburger.png';
import VerticalLine from '../assets/images/vertical-lines.png';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from "react-router-dom";
import './TopNavbar.css';

const menuItems = [
    { id: 1, name: 'Home', link: '/' },
    { id: 2, name: 'About Us', link: '/about', submenu: ['About Intellection', 'Why Choose Us?'] },
    { id: 3, name: 'Solutions', link: '/solutions' },
    { id: 4, name: 'Our Work', link: '/work' },
    { id: 5, name: 'Our Products', link: '/products' },
    { id: 6, name: 'Career', link: '/career' },
    { id: 7, name: 'Contact Us', link: '/contact' },
];

const TopNavbar = () => {
  
    const navigation = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [aboutSubMenuOpen, setAboutSubMenuOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
        setAboutSubMenuOpen(false); // Close submenu when Offcanvas is closed
    };

    const handleShow = () => setIsOpen(true);

    const handleMenuItemClick = (link, submenuItem = null) => {
        if (link === '/about') {
            // If submenu item is provided, navigate to its corresponding route
            if (submenuItem) {
                const submenuLink = `/about/${submenuItem.toLowerCase().replace(/\s+/g, '-')}`;
                navigation(submenuLink);
                setIsOpen(false);
            } else {
                // Toggle submenu visibility
                setAboutSubMenuOpen(!aboutSubMenuOpen);
            }
        } else {
            setIsOpen(false);
            navigation(link);
        }
    };


    return (
        <>
            <div className="d-none d-md-block" style={{ position: 'fixed', zIndex: 1000, backgroundColor: '#fff', left: 0, top: 0, right: 0 }}>
                <div className="TopNav " style={{}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ padding: '0 8px', marginTop: 1 }}>
                            <a href="tel:+912025362888">  <img src={call} alt="call" width={18} /> </a>
                        </div>
                        <div style={{ padding: '0 8px', marginTop: 3 }}>
                            <a href="mailto:contact@intellection.in"> <img src={email} alt="email" width={25} /></a>
                        </div>
                        <div style={{ padding: '0 8px', marginTop: '-3px' }}>
                            <a target="_blank" href="https://www.linkedin.com/company/intellection-software-&-technologies-pvt-ltd" > <img src={linkedin} alt="linkedin" width={19} /></a>
                        </div>
                    </div>
                    <p className="d-none d-md-block" style={{ margin: 'auto', textAlign: 'center' }}> {/* Center the text on large devices */}
                        We are creative, ambitious and ready for challenges! <span><strong style={{ color: COLORS.heading }}>HIRE US</strong></span>
                    </p>
                    <div style={{ marginTop: '-20px' }}>
                        <img src={Logo} alt="logo" height={40} />
                    </div>
                </div>
            </div>

            {/* show only for small screen */}
            <div className="d-lg-block d-lg-none" style={{ position: 'fixed', zIndex: 1000, backgroundColor: '#fff', left: 0, top: 0, right: 0, paddingTop: '10px' }}>
                <div className="TopNavbar" style={{ paddingBottom: 20 }}>
                    <div style={{ marginTop: 20 }}>
                        <img
                            src={isOpen ? VerticalLine : Humburger}
                            width={30}
                            height={20}
                            onClick={handleShow}
                            className="menu-icon"
                        />
                        <Offcanvas show={isOpen} onHide={handleClose} style={{ width: '62%' }}>
                            <Offcanvas.Header closeButton />
                            <Offcanvas.Body>
                                <ul className="menu-list" style={{ listStyle: 'none', paddingLeft: '0rem' }}>
                                    {menuItems.map(item => (
                                        <li key={item.id} style={{ paddingBottom: '1rem' }} className="menu-item" onClick={() => handleMenuItemClick(item.link)}>
                                            {item.name}
                                            {/* Conditionally render the submenu for "About Us" */}
                                            {item.link === '/about' && aboutSubMenuOpen && (
                                                <ul style={{ listStyle: 'none' }}>
                                                    {item.submenu.map((submenuItem, index) => (
                                                        <li key={index} onClick={() => handleMenuItemClick(item.link, submenuItem)}>
                                                            {submenuItem}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                    <div>
                        <a href="/"> <img src={Logo} alt="logo" height={40} /></a>
                    </div>
                </div>
            </div>

        </>
    );
}

export default TopNavbar;
