import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './GlobalMap.css'; // Custom CSS file
import map from '../assets/images/map.jpg'


const GlobalMap = () => {
  return (
    <>
      {/* <Row className="map-container">
        <Col  className="map-text">
          <h2 style={{ fontSize: '34px' }}>Innovating Solutions,<br /> Transforming Businesses</h2>
          <p style={{ paddingLeft: '20px' }}>Intellection operates on a global scale, bringing innovative software solutions to clients around the world. Our presence in multiple regions allows us to understand and adapt to the unique needs of each market, ensuring that we deliver services that truly make a difference. By connecting with diverse industries and cultures, we harness global expertise to create customized solutions that drive success.</p>
        </Col>
        <Col >
         <img src={map}  className="img-fluid" />
        </Col>
     
      </Row> */}
      <Container fluid className="custom-box">
        <Row>
          {/* Column for text */}
          <Col md={5} className="map-text">
          <h2 style={{ fontSize: '34px' }}>Innovating Solutions,<br /> Transforming Businesses</h2>
          <p style={{ paddingLeft: '20px' }}>Intellection operates on a global scale, bringing innovative software solutions to clients around the world. Our presence in multiple regions allows us to understand and adapt to the unique needs of each market, ensuring that we deliver services that truly make a difference. By connecting with diverse industries and cultures, we harness global expertise to create customized solutions that drive success.</p>
          </Col>

          {/* Column for image */}
          <Col md={7} className="img-col">
            <img
              src={map}
              alt="Map"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GlobalMap;
