import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa'; // Import the language icon
import logo from '../assets/images/IntellectionLogo.png';
import REVA from '../assets/images/ReVALogo.png';
import iElection from '../assets/images/iElection1.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import translation hook
import './NavBar.css';

const NavBar = () => {
    const location = useLocation();
    const [showProductsDropdown, setShowProductsDropdown] = useState(false);
    const [showWorkDropdown, setShowWorkDropdown] = useState(false);
    const [showAboutDropdown, setShowAboutDropdown] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showOffcanvas, setShowOffcanvas] = useState(false); // For the sidebar

    const { t } = useTranslation(); // Initialize translation with t function

    const getLinkClassName = (path) => {
        return location.pathname === path ? 'nav-link active' : 'nav-link';
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDropdown = (setter) => {
        if (isMobile) {
            setter((prev) => !prev);
        }
    };

    const handleMouseEnter = (setter) => {
        if (!isMobile) {
            setter(true);
        }
    };

    const handleMouseLeave = (setter) => {
        if (!isMobile) {
            setter(false);
        }
    };

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate('/product-details');
    };

    const handleCardClick1 = () => {
        navigate('/iElection-details');
    };

    // Function to handle showing the offcanvas
    const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    return (
        <>
            <Navbar
                style={{ backgroundColor: 'white', marginBottom: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                expand="lg"
                fixed="top"
            >
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} height="40" className="d-inline-block align-top" alt="Logo" />
                    </Navbar.Brand>

                    {/* Toggle button to open the sidebar */}
                    <Navbar.Toggle onClick={handleToggleOffcanvas} aria-controls="offcanvasNavbar" />
                    {/* Remove Navbar.Collapse entirely */}

                    {/* For large screens, display the regular navbar */}
                    {!isMobile && (
                        <Nav className="mx-auto">
                            <Nav.Link
                                style={{ padding: '0px 20px', position: 'relative' }}
                                className={getLinkClassName('/about')}
                                onMouseEnter={() => handleMouseEnter(setShowAboutDropdown)}
                                onMouseLeave={() => handleMouseLeave(setShowAboutDropdown)}
                                onClick={() => toggleDropdown(setShowAboutDropdown)}
                            >
                                {t('company')} <FaChevronDown style={{ marginLeft: '8px' }} />
                                {showAboutDropdown && (
                                    <div className="custom-dropdown1">
                                        <Link to="/company-overview" className={getLinkClassName('/company-overview')}>
                                            {t('about_us')}
                                        </Link>
                                        <Link to="/leadership" className={getLinkClassName('/leadership')}>
                                            {t('leadership')}
                                        </Link>
                                        <Link to="/testimonial" className={getLinkClassName('/testimonial')}>
                                            {t('testimonials')}
                                        </Link>
                                    </div>
                                )}
                            </Nav.Link>
                            <Nav.Link as={Link} style={{ padding: '0px 20px' }} to="/services" className={getLinkClassName('/services')}>
                                {t('services')}
                            </Nav.Link>
                            <Nav.Link
                                style={{ padding: '0px 20px', position: 'relative' }}
                                className={getLinkClassName('/work')}
                                onMouseEnter={() => handleMouseEnter(setShowWorkDropdown)}
                                onMouseLeave={() => handleMouseLeave(setShowWorkDropdown)}
                                onClick={() => toggleDropdown(setShowWorkDropdown)}
                            >
                                {t('ourwork')} <FaChevronDown style={{ marginLeft: '8px' }} />
                                {showWorkDropdown && (
                                    <div className="custom-dropdown1">
                                        <Link to="/work?category=media-entertainment" className={getLinkClassName('/work?category=media-entertainment')}>{t('media_entertainment')}</Link>
                                        <Link to="/work?category=healthcare" className={getLinkClassName('/work?category=healthcare')}>{t('healthcare')}</Link>
                                        <Link to="/work?category=utility-solution" className={getLinkClassName('/work?category=utility-solution')}>{t('utility_solution')}</Link>
                                        <Link to="/work?category=manufacturing" className={getLinkClassName('/work?category=manufacturing')}>{t('manufacturing')}</Link>
                                        <Link to="/work?category=education" className={getLinkClassName('/work?category=education')}>{t('education')}</Link>
                                    </div>
                                )}
                            </Nav.Link>
                            <Nav.Link
                                style={{ padding: '0px 20px', position: 'relative' }}
                                className={getLinkClassName('/products')}
                                onMouseEnter={() => handleMouseEnter(setShowProductsDropdown)}
                                onMouseLeave={() => handleMouseLeave(setShowProductsDropdown)}
                                onClick={() => toggleDropdown(setShowProductsDropdown)}
                            >
                                {t('our_products')} <FaChevronDown style={{ marginLeft: '8px' }} />
                                {showProductsDropdown && (
                                    <div className={`custom-dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
                                        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{t('products_platforms')}</p>
                                        <p>At Intellection, we lead the way in innovation by crafting world-class products with our proprietary software solutions. Our solutions are not only efficient and reliable but also continuously updated with the latest advancements, ensuring your product remains cutting-edge. Allow us to bring your visionary ideas to life. Here’s a glimpse of the innovative products we’ve developed in-house!</p>
                                        <div className="custom-img">
                                            <img onClick={handleCardClick} src={REVA} alt='Reva' width={200} height={150} />
                                            <img src={iElection} onClick={handleCardClick1} width={250} height={140} />
                                        </div>
                                    </div>
                                )}
                            </Nav.Link>
                            <Nav.Link as={Link} style={{ padding: '0px 20px' }} to="/career" className={getLinkClassName('/career')}>
                                {t('careers')}
                            </Nav.Link>
                        </Nav>
                    )}
                    {/* Button for contact us */}
                    {!isMobile && (
                        <Button as={Link} to="/contact-us" className="contactUs">
                            {t('contact_us')}
                        </Button>
                    )}
                </Container>
            </Navbar>

            {/* Offcanvas sidebar for small screens */}
            <Offcanvas show={showOffcanvas} onHide={handleToggleOffcanvas} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src={logo} height="40" className="d-inline-block align-top" alt="Logo" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        {/* Company dropdown */}
                        <div>
                            <div
                                className="nav-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleDropdown(setShowAboutDropdown)}
                            >
                                {t('company')} <FaChevronDown style={{ marginLeft: '8px' }} />
                            </div>
                            {showAboutDropdown && (
                                <div className="dropdown-content" style={{ marginLeft: 30 }}>
                                    <Nav.Link
                                        as={Link}
                                        to="/company-overview"
                                        className={getLinkClassName('/company-overview')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('about_us')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/leadership"
                                        className={getLinkClassName('/leadership')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('leadership')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/testimonial"
                                        className={getLinkClassName('/testimonial')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('testimonials')}
                                    </Nav.Link>
                                </div>
                            )}
                        </div>

                        {/* Services link */}
                        <Nav.Link as={Link} to="/services" className={getLinkClassName('/services')} onClick={handleToggleOffcanvas}>
                            {t('services')}
                        </Nav.Link>

                        {/* Our Work dropdown */}
                        <div>
                            <div
                                className="nav-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleDropdown(setShowWorkDropdown)}
                            >
                                {t('ourwork')} <FaChevronDown style={{ marginLeft: '8px' }} />
                            </div>
                            {showWorkDropdown && (
                                <div className="dropdown-content" style={{ marginLeft: 30 }}>
                                    <Nav.Link
                                        as={Link}
                                        to="/work?category=media-entertainment"
                                        className={getLinkClassName('/work?category=media-entertainment')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('media_entertainment')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/work?category=healthcare"
                                        className={getLinkClassName('/work?category=healthcare')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('healthcare')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/work?category=utility-solution"
                                        className={getLinkClassName('/work?category=utility-solution')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('Utility Solution')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/work?category=manufacturing"
                                        className={getLinkClassName('/work?category=manufacturing')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('Manufacturing')}
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/work?category=education"
                                        className={getLinkClassName('/work?category=education')}
                                        onClick={handleToggleOffcanvas}
                                    >
                                        {t('Education')}
                                    </Nav.Link>
                                </div>
                            )}
                        </div>

                        {/* Our Products dropdown */}
                        <div>
                            <div
                                className="nav-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleDropdown(setShowProductsDropdown)}
                            >
                                {t('our_products')} <FaChevronDown style={{ marginLeft: '8px' }} />
                            </div>
                            {showProductsDropdown && (
                                <div className="dropdown-content">
                                    <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{t('products_platforms')}</p>
                                    <p>At Intellection, we lead the way in innovation by crafting world-class products with our proprietary software solutions.</p>
                                    <div className="custom-img">
                                        <img onClick={() => { handleCardClick(); handleToggleOffcanvas(); }} src={REVA} alt="Reva" width={200} height={150} />
                                        <img onClick={() => { handleCardClick1(); handleToggleOffcanvas(); }} src={iElection} alt="iElection" width={250} height={140} />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Careers link */}
                        <Nav.Link as={Link} to="/career" className={getLinkClassName('/career')} onClick={handleToggleOffcanvas}>
                            {t('careers')}
                        </Nav.Link>

                        {/* Contact Us button */}
                        <Button as={Link} to="/contact-us" className="contactUs" onClick={handleToggleOffcanvas}>
                            {t('contact_us')}
                        </Button>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};

export default NavBar;
