import React,{useEffect} from 'react'
import About from '../Components/About'
import NavBar from '../Components/NavBar'
import OurVision from '../Components/OurVision'
import OurLeadership from '../Components/OurLeadership'
import Experience from '../Components/Experience'
import OurTeam from '../Components/OurTeam'
import OurValues from '../Components/OurValues'
import WhyChooseUs from '../Components/WhyChooseUs';

const AboutUs = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <About />
            {/* <Experience /> */}
            <OurVision />
            <OurValues />
            <WhyChooseUs />
            {/* <PopularTech /> */}
            {/* <OurLeadership /> */}
            {/* <OurTeam /> */}
        </>
    )
}
export default AboutUs