import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import { FONT } from '../utils/Font'
import Ideation from '../assets/images/Ideation.png'
import Exceution from '../assets/images/Exceution.png'
import leftImage from '../assets/images/lefttImage.png'
import rightImage from '../assets/images/rightImage.png';
import TechStackCardComponent from './TechStackCardComponent'
import Speed from '../assets/images/speed.png'
import Development from '../assets/images/development.png'
import Communication from '../assets/images/communication.png'

import './Navv.css'
// const Data = [
//     {
//         img: Ideation,
//         heading: "Brilliant Ideation"
//     },
//     {
//         img: Exceution,
//         heading: "Flawless Execution"
//     },
// ]

const Opening = [
    { language: 'React Js' },
    { language: 'React Native' },
    { language: 'Full Stack Developer' },
    { language: 'Java Developer' },
    { language: 'UI/UX Designer' },
    { language: 'Graphic Designer' },
]

const Data = [
    {
        title: "Web Development",
        list: [
            "Computer Engineer",
            "Kothrud, Pune",
            "1-3 years experience",
        ],
        img: Speed
    },
    {
        title: "Full Stack Developer",
        list: [
            "Computer Engineer",
            "Kothrud, Pune",
            "1-3 years experience",
        ],
        img: Communication
    },
    {
        title: "React Native Developer",
        list: [
            "Computer Engineer",
            "Kothrud, Pune",
            "1-3 years experience",
        ],

        img: Development
    },

];

const Careers = () => {
    return (
        <>
            <div style={{
                marginTop: '5%',
                padding: 50,
                textAlign: 'center',
                background: 'linear-gradient(180deg, rgba(111, 45, 168, 0.03) -54.1%, #FFFFFF 63.14%)',
                position: 'relative'
            }}>
                <img
                    src={leftImage}
                    alt="Left Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        height: '170px',
                        width: '300px'
                    }}
                />
                <img
                    src={rightImage}
                    alt="Right Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: 'auto'
                    }}
                />
                <p style={{ fontFamily: FONT.fontBold, fontWeight: 700, fontSize: 45, marginTop: 50 }}>
                    Careers at <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Intellection</span>
                </p>
                <div style={{
                    textAlign: 'center',
                    margin: '0 auto',

                }}>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20 }}>
                        At Intellection, we thrive on challenging responsibilities. Our belief
                    </p>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20, marginTop: '-15px' }}>is that growth comes from embracing the tough tasks.</p>
                </div>
            </div>
            <div style={{textAlign:'center', fontFamily: FONT.primaryFont,}}>
            <h2 style={{ fontFamily: FONT.fontBold,}}>Join Our Team</h2>
            <p>We're always looking for talented individuals to join our software company. If you're passionate about technology and innovation, we'd love to hear from you.</p>
            <p>Please send your resume to <a href="mailto:careers@intellection.in">careers@intellection.in</a></p>
            </div>
            <div className='WhatWeDoComponent' style={{ marginBottom: 100 }}>
                <div style={{ marginTop: 100 }}>
                    {/* <p style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: FONT.primaryFont, fontSize: 42, fontWeight: 'bold', textAlign: 'center' }}>Recent Openings</p> */}


                    {/* <Row style={{ marginTop: 70 }}>
                        {Data.map((item, index) => (
                            <TechStackCardComponent item={item} index={index} />
                        ))}
                    </Row> */}
                    {/* <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginBottom: '-17px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '17px' }}>
                            {Opening.map((item, index) => (
                                <div key={index} style={{ height: '121px', width: '286px', border: `1px solid ${COLORS.heading}`, boxShadow: '1px 4px 5px grey', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 25 }}>
                                    <p style={{ fontSize: 24, fontWeight: 'bold', color: COLORS.paragraph, textAlign: 'center', margin: 0 }}>{item.language}</p>
                                </div>
                            ))}
                        </div>
                    </div> */}

                </div>
            </div>
        </>
    )
}
export default Careers