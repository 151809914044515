import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { COLORS } from '../utils/Colors'
import { FONT } from '../utils/Font'
import Logo from '../assets/images/IntellectionLogo.png';
import email from '../assets/images/email.png';
import call from '../assets/images/phone-call.png';
import linkedin from '../assets/images/linkedin.png';
import './NavBar.css'
const Footer = () => {
    return (
        <>
            <footer className='footercard' style={{ backgroundColor: '#f8f9fa', marginTop: 100 }}>
                {/* <div> */}
                <Row>
                    <Col md={4}>
                        <img src={Logo} alt="Intellecticon" style={{ marginBottom: '20px', marginTop: '-40px' }} />
                        <p>
                            Intellection Software & Technologies Pvt. Ltd.<br/>
                                Shriprasad, 5<sup>th</sup> Floor, Plot No. 74, Paud Road, Kothrud, Pune- 411038, India
                        </p>

                    </Col>
                        <Col md={4}>
                            <h5>Quick Links</h5>
                            <ul style={{ listStyleType: 'none', padding: 0, marginTop: 20 }}>
                                <li><Link className="quick-link" to="/company-overview">About Us</Link></li>
                                <li><Link className="quick-link" to="/work">Portfolio</Link></li>
                                <li><Link className="quick-link" to="/services">Services</Link></li>
                                <li><Link className="quick-link" to="/career">Careers</Link></li>
                            </ul>
                        </Col>
                        <Col md={4}>
                            <h5>Get in Touch</h5>
                            <ul style={{ listStyleType: 'none', padding: 0, marginTop: 20 }}>
                                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={call} style={{ marginRight: '10px' }} width={25} alt="Call Icon" />
                                    <span> <a href="tel:+918468947331" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 8468947331
        </a></span>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={email} style={{ marginRight: '10px' }} width={25} alt="Email Icon" />
                                    <a
                                        href="mailto:contact@intellection.in"
                                        style={{ color: 'black', textDecoration: 'none' }}
                                    >
                                        contact@intellection.in
      </a>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={linkedin} style={{ marginRight: '10px' }} width={25} alt="LinkedIn Icon" />
                                    <a
                                        href="https://www.linkedin.com/company/intellection-software-&-technologies-pvt-ltd/"
                                        style={{ color: 'black', textDecoration: 'none' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        LinkedIn
      </a>
                                </li>
                            </ul>
                        </Col>

                </Row>
                    <Row>
                        {/* <Col md={6} style={{ marginTop: '20px', borderTop: '1px solid #ddd', paddingTop: '10px' }}> */}
                            {/* <span style={{ marginRight: '15px' }}>ISO 9001:2015</span> */}
                            {/* <span>Terms & Conditions</span> */}
                        {/* </Col> */}
                        <Col md={12} style={{ marginTop: '20px', borderTop: '1px solid #ddd', paddingTop: '10px' }}>
                            <div style={{ textAlign: 'right', }}>© 2024 Intellecticon Software & Technologies Pvt. Ltd.</div>
                            {/* <p>
                        ISO 9001:2015
                        </p> */}
                        </Col>
                    </Row>
                    {/* </div> */}
            </footer>
        </>
    )
}
export default Footer