import React,{useEffect} from 'react';
import AboutUsHome from '../Components/AboutUsHome';
import Experience from '../Components/Experience';
import NavBar from '../Components/NavBar';
import OurExpertise from '../Components/OurExpertise';
import OurServices from '../Components/OurServices';
import ProjectCaseStudies from '../Components/ProjectCaseStudies';
import Testimonial from '../Components/Testimonial';
import OurClient from '../Components/OurClient';
import ImgCard from '../Components/ImgCard';
import GlobalMap from '../Components/GlobalMap';
import ContactWithUs from '../Components/ContactWithUs';
import OurWorkFlow from '../Components/OurWorkFLow';
import CarouselComponent from '../Components/CarouselComponent';
import WhyChooseUs from '../Components/WhyChooseUs';
import TechStack from '../Components/TechStack';
import TechServices from '../Components/TechServices';

const Home = () =>{
    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
        <NavBar />
        <ImgCard />
        <Experience />
        <WhyChooseUs />

        <GlobalMap />
        {/* <AboutUsHome /> */}
        <OurServices />
        {/* <TechServices /> */}
        {/* <OurWorkFlow /> */}
        <OurExpertise title="Industries We Deliver Innovative Solutions" />
        <ContactWithUs />
       
        <OurClient />
        {/* <ProjectCaseStudies /> */}
        {/* <Testimonial /> */}
        </>
    )
}
export default Home