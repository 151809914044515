import React from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import REVAProject from '../assets/images/revanewlogo.png';
import Navv from './Navv'
import Form from 'react-bootstrap/Form';
import REVA from '../assets/images/ReVALogo.png';
import bannerVideo from '../assets/video/Reva1.mp4';
import NavBar from './NavBar';


const OurProductDetailPage = () => {
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);

    // Check for screen size on mount and resize
    React.useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 767); // Detect if screen width is <= 767px
        };

        handleResize(); // Set initial state based on window size
        window.addEventListener('resize', handleResize); // Add listener for resize event

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <div className="video-container" style={{ marginTop: isSmallScreen ? '50px' : '0' }}>
                <video width="100%" height="auto" autoPlay loop muted>
                    <source src={bannerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>


            <div className='WhatWeDoComponent' style={{ marginTop: '10px', marginBottom: '100px' }}>
                <img src={REVA} alt='Reva' width={200} height={150} />
                <Row>
                    <Col lg={7} md={12}>
                        <p style={{  marginTop: 20, width: '95%', fontFamily: FONT.fontBold, fontSize: 20 }}>ReVA - Advanced Channel Viewership Analytics Platform</p>
                        <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>ReVA is an innovative, web-based platform meticulously crafted to revolutionize the way you understand and analyze channel viewership. Designed with both individual users and households in mind, ReVA delivers deep insights into viewing behaviors, enabling a comprehensive understanding of audience patterns. Whether you're tracking the habits of a single user or examining the collective viewing tendencies of entire households, ReVA equips you with the tools to make informed decisions.</p>
                        <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>At the heart of ReVA lies its proprietary algorithm, a powerful feature that intelligently groups users into households. This unique capability provides a detailed and accurate picture of collective viewing habits, allowing you to discern not just what content is being consumed, but how it resonates within different household dynamics.</p>
                    </Col>
                    <Col lg={5}>
                        <img src={REVAProject} className='img-fluid' width={450} style={{ border: '1px solid grey' }} />

                    </Col>

                </Row>

                <div>

                   
                    <p style={{ textAlign: 'justify', marginTop: 10, width: '95%', fontFamily: FONT.primaryFont }}>ReVA goes beyond mere data analysis by empowering users to create customized target groups based on specific viewership and demographic criteria. These tailored segments can be analyzed and visualized using a variety of filters, offering a nuanced perspective on viewing trends. Whether you’re interested in a particular age group, geographic location, or content genre, ReVA provides the flexibility to focus on the metrics that matter most to your goals.</p>
                    <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>Furthermore, ReVA takes your content strategy to the next level by recommending the most effective channels for displaying promotional material. By leveraging viewership data and advanced analytics, ReVA identifies the optimal platforms to maximize audience engagement, ensuring your promotional content reaches the right viewers at the right time.</p>
                    <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>In summary, ReVA is not just a tool for analyzing viewership; it is a comprehensive platform designed to enhance your understanding of audience behavior, streamline your marketing strategies, and ultimately, drive better results. Whether you are a broadcaster, marketer, or content creator, ReVA offers the insights and analytics you need to stay ahead in a competitive landscape.</p>

                </div>
            </div>
        </>
    )
}
export default OurProductDetailPage