import { Container, Row, Col, Card } from "react-bootstrap"
import React from 'react'
import { COLORS } from '../utils/Colors';
import { FONT } from "../utils/Font";
import ProjectBg from '../assets/images/backgroundProject.png'
import Meditation from '../assets/images/meditation.png'
import computer from '../assets/images/computer.png'
import AdirA from '../assets/images/AdirA.png'
import BellyBaloo from '../assets/images/BellyBaloo.png'
import OurProjectCardComponent from "./OurProjectCardComponent";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Navv.css'
const Data = [
    {
        img: Meditation,
        heading: 'ReVA',
        paragraph: 'Entertainment / Web Platform'
    },
    {
        img: computer,
        heading: 'BARC India',
        paragraph: 'Service/ Web Platform'
    },
    {
        img: AdirA,
        heading: 'AdirA',
        paragraph: 'Healthcare/ Web Platform'
    },
    {
        img: BellyBaloo,
        heading: 'BellyBaloo',
        paragraph: 'Healthcare/ Mobile Apps/ Utility Solution'
    },
    {
        img: Meditation,
        heading: 'ReVA',
        paragraph: 'Entertainment / Web Platform'
    },
    {
        img: computer,
        heading: 'BARC India',
        paragraph: 'Service/ Web Platform'
    },
   

];

const OurProduct = () => {
    return (
        <>
           <div style={{marginBottom:106}}>
            <div className="custom-software-container" >
                <p className="main-title" style={{ fontFamily: FONT.primaryFont }}>
                    Our <span className="highlighted"> Product</span>
                </p>

                <div className="text-container">
                    <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>Customized solutions that have helped our clients achieve their specific</p>
                    <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>business goals.</p>
                </div>
            </div>
            <Tabs
                defaultActiveKey="All Works"
                id="uncontrolled-tab-example"
                className="mb-3 tabsmain"
                style={{  border: 'none' }}
            >
                <Tab eventKey="All Works" title="All Works" className='tabsmain' style={{  border: 'none' }}>
                    <Row>
                        {Data.map((item, index) => (
                            <Col key={index} lg={4} md={4} sm={12} className="carousel-item-col">
                                <Card className="text-white" style={{marginBottom:20}}>
                                    <Card.Img className='img-hover' src={item.img} alt={item.alt} />
                                    <div className="img-overlay">
                                        <Card.Title>{item.heading}</Card.Title>
                                        <Card.Text>{item.paragraph}</Card.Text>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Tab>
                <Tab eventKey="Mobile Apps" title="Mobile Apps" style={{ padding: '0px 60px', border: 'none' }}>
                    Tab content for Profile
                </Tab>
                <Tab eventKey="Web Platform" title="Web Platform" style={{ padding: '0px 60px', border: 'none' }}>
                    Tab content for Contact
                </Tab>
            </Tabs>

        </div>

        </>
    )
}
export default OurProduct