import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import Speed from '../assets/images/speed.png'
import Development from '../assets/images/development.png'
import Communication from '../assets/images/communication.png'
import TechStackCardComponent from './TechStackCardComponent'
import techstackbg from '../assets/images/TechStackBgImg.png'
import { Link, withRouter } from 'react-router-dom';
import './NavBar.css'
import { FONT } from '../utils/Font'
const Data = [
    {
        title: "Web Development",
        list: [
            "ReactJS, AngularJS",
            "NodeJS, Django, Flask",
            "Laravel, CodeIgniter",
            "Apache Drill, Hadoop, Spark, Zookeeper",
            "Python, Java, R, C#, PHP",
            "MSSQL, MySQL, PostgreSQL, MongoDB",

        ],
        img: Speed
    },
    {
        title: "Mobile App Development",
        list: [
            "Native iOS development ",
            "Native Android development in Kotlin",
            "React Native based Cross-platform apps",
            "Flutter"
        ],
        img: Communication
    },
    {
        title: "Technology Development",
        list: [
            "AI-ML Based Algorithm Development",
            "Blockchain Based Development",
            "Image/Video Processing",
            "CAD/CAM Development",
            "Data Science and Data Visualization"
        ],
        img: Development
    },

];

const TechStack = ({ history }) => {
    const handleButtonClick = () => {
        history.push('/contact');
    };

    return (
        <>
            <div className="WhatWeDoComponent" style={{ marginTop: 100,marginBottom:100 }}>
                <p className='developmenttechStack' style={{ color: 'rgba(0, 0, 0, 1)',  fontWeight: '600', textAlign: 'center', fontFamily: FONT.primaryFont }}>Development <span  style={{ color: 'rgba(111, 45, 168, 1)' }}>Tech Stacks</span></p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p className="techstackpara" style={{ fontSize: 20, color: 'rgba(0, 0, 0, 1)', textAlign: 'center',  fontFamily: FONT.primaryFont }}>Our engineers use the latest trends and technologies to deliver your product with a future-proof tech stack.</p>
                </div>
                <Row style={{marginTop:70}}>
                    {Data.map((item, index) => (
                        <TechStackCardComponent item={item} index={index} />
                    ))}
                </Row>
            </div>



        </>
    )
}
export default TechStack
