import React from 'react'
import { Col } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'

const TechStackCardComponent = ({ item, index }) => {
    return (
        <>
            <Col lg={4} md={6} sx={12} key={index} className="d-flex">
                <div className="custom-container">
                    <img src={item.img} width={50} height={50} alt="Item" />
                    <p className="item-title">{item.title}</p>
                    <ul className="item-list">
                        {item.list.map((listItem, listIndex) => (
                            <li key={listIndex}>{listItem}</li>
                        ))}
                    </ul>
                </div>
            </Col>


        </>
    )
}
export default TechStackCardComponent