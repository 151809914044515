import React from 'react'
import Careers from '../Components/Careers'
import NavBar from '../Components/NavBar'
import Navv from '../Components/Navv'

const Career = () => {
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <Careers />
        </>
    )
}
export default Career